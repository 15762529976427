import React, { useEffect } from 'react';
import { navigate } from 'gatsby';
import supportedLanguages from '../data/supportedLanguages';

const IndexPage = () => {
  useEffect(() => {
    // Liste der unterstützten Sprachen
    //const supportedLanguages = ['en', 'de', /* weitere unterstützte Sprachcodes */];

    
    // Versuchen, die gespeicherte Sprache aus dem Local Storage zu lesen
    let savedLang = localStorage.getItem('preferredLanguage');

    if (!savedLang) {
      // Ermitteln der Browsersprache
      const browserLang = (navigator.language || navigator.userLanguage).substring(0, 2);

      // Überprüfen, ob die Browsersprache unterstützt wird, sonst "en" verwenden
      savedLang = supportedLanguages.includes(browserLang) ? browserLang : 'en';

      // Speichern der ermittelten oder Standard-Sprache im Local Storage
      localStorage.setItem('preferredLanguage', savedLang);
    }

    // Umleitung zur entsprechenden Sprachversion
    navigate(`/${savedLang}/`);
  }, []);

  return (
    <div>
            <nav className="sticky top-0 p-1 py-10 flex bg-gradient-to-b from-yellow-200 to-yellow-100 rounded-b-lg border-b mx-auto max-w-4xl"></nav>
      
    </div>
  );
};

export default IndexPage;
